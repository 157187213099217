import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import './style/index.scss';
import 'animate.css/animate.css';
import { useDispatch } from 'react-redux';
import { setViewportInfo } from './state/app';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const Available = lazy(() => import('./view/Available'));
const Home = lazy(() => import('./view/Home'));
const Mission = lazy(() => import('./view/Mission'));
const Introduction = lazy(() => import('./view/Home/Introduction'));
// const Imo = lazy(() => import('./view/Imo'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const onWindowResize = () => {
    dispatch(setViewportInfo({ width: window.innerWidth, height: window.innerHeight }));
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return function () {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return (
    <Suspense fallback={null}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/available" exact component={Available} />
          <Route path="/home" exact component={Home} />
          <Route path="/mission" exact component={Mission} />
          {/* <Route path="/imo" exact component={Imo} /> */}
          {/* <Route path="/introduction" exact component={Introduction} /> */}
          <Redirect to="/home" />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default React.memo(App);
