import React from 'react';
import { withRouter } from 'react-router-dom';
import { setRouterProps } from 'state/app';
import store from '../../state';

class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prevProps) {
    const { hash, pathname } = this.props.location;
    const { pathname: prePathname, hash: preHash } = prevProps.location;
    if (pathname !== prePathname || !hash) {
      window.scrollTo(0, 0);
    }
    store.dispatch(setRouterProps({ current: this.props.location, pre: prevProps.location }));
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop as any);
