import { configureStore, createReducer, createSlice } from '@reduxjs/toolkit';
import appReducer from './app';
// import farmsReducer from './farms'

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    app: appReducer,
    farms: createSlice({ name: 'farms', initialState: {}, reducers: {} }).reducer,
  },
});
