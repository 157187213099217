import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const RESPONSIVE_WIDTH = 1200;

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < RESPONSIVE_WIDTH,
    routerProps: { currentLocation: {}, preLocation: {} },
  },
  reducers: {
    setViewportInfo: (state, action: PayloadAction<{ width: number; height: number }>) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.isMobile = state.width < RESPONSIVE_WIDTH;
    },
    setRouterProps: (state, action) => {
      state.routerProps.currentLocation = action.payload.current;
      state.routerProps.preLocation = action.payload.pre;
    },
  },
});

export const { setViewportInfo, setRouterProps } = appSlice.actions;

export default appSlice.reducer;
