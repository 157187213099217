import React from 'react';
import { Provider } from 'react-redux';
import store from 'state';

export default class Providers extends React.Component<any, any> {
  render() {
    const { children } = this.props;
    return <Provider store={store}>{children}</Provider>;
  }
}
